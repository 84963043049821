@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&display=swap');

.desktop{
    display: flex;
    height: 100vh;
}

.mobile{
    display: flex;
    height: 100vh;
}

.left{
    background-color: #F2AA61;
    width: 32%;  
    font-family: 'Inter', inter;
    display: flex;
}

.right{
    width: 68%;
    font-family: 'Inter', inter;
}

.circle1{
    position: absolute;
    opacity: 15%;
    width: 27%;       /* set width of the circle */
    height: 50%;      /* set height of the circle */
    background-color: #E99846; /* set color of the circle */
    border-radius: 50%; /* this makes it a circle! */
    z-index: -1;
    left: 30%;
    top : -13%
}

.mob_circle1{
    position: absolute;
    opacity: 15%;
    width: 55%;       /* set width of the circle */
    height: 30%;      /* set height of the circle */
    background-color: #E99846; /* set color of the circle */
    border-radius: 50%; /* this makes it a circle! */
    z-index: -1;
    left: 20%;
    top : -3%
}

.circle2{
    position: absolute;
    opacity: 15%;
    width: 27%;       /* set width of the circle */
    height: 50%;       /* set height of the circle */
    background-color: #E99846; /* set color of the circle */
    border-radius: 50%; /* this makes it a circle! */
    z-index: -1;
    left: 83%;
    top : 33%
}

.circle3{
    position: absolute;
    opacity: 15%;
    width: 27%;       /* set width of the circle */
    height: 50%;      /* set height of the circle */
    background-color: #E99846; /* set color of the circle */
    border-radius: 50%; /* this makes it a circle! */
    z-index: -1;
    left: 40%;
    top : 40%
}

.gridImage{
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 0;
}

.mob_gridImage{
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 0;
}

.mobile_left{
    width: 100%;
}

.ma{
    color: #FFFFFF;
    font-size: 18vh;
    margin-top: 6.5%;
    width: 100%;
    text-align: right;
}

.mob_ma{
    color: #FFFFFF;
    font-size: 7vh;
    margin-top: 40%;
    text-align: right;
    width: 100%;
}

.mob_mi{
    color: #FFFFFF;
    font-size: 7vh;
    margin-top: 0%;
    text-align: right;
    width: 100%;
}

/* .ma{
    position: absolute;
    color: #FFFFFF;
    font-size: 18vh;
    width: fit-content;
    display: flex;
    /* background-color: #6A3C0E; */
    /* margin-left: 17.8%; */
/* } */ 

.t1{
    /* background-color :#de8730; */
    width: fit-content;
}

.t2{
    /* background-color :#de8730; */
    width: fit-content;
    color: #F2AA61;
}

.ple{
    color: #F2AA61;
    font-size: 18vh;
    /* background-color: #e94646; */
    margin-top: 3%;
    width: 100%;
    text-align: left;
}

.mob_ple{
    color: #F2AA61;
    font-size: 7vh;
    margin-top: 19%;
    width: 100%;
    text-align: left;
}

.mob_nutes{
    color: #F2AA61;
    font-size: 7vh;
    margin-top: 0%;
    width: 100%;
    text-align: left;
}

.newsInANutshell{
    color: #FFFFFF;
    font-size: 4.3vh;
    /* background-color: #e94646; */
    margin-top: -7%;
    width: 100%;
    text-align: right;
}

.essence{
    color: #F2AA61;
    font-size: 4.3vh;
    /* background-color: #e94646; */
    margin-top: -3.7%;
    width: 100%;
    margin-left: 4%;
}

.l1{
    position: absolute;
    color: #6A3C0E;
    margin-top: 1.5%;
    font-size: 2.65vh;
    width: 100%;
    text-align: center;
    
}

.mob_l1{
    position: absolute;
    color: #6A3C0E;
    margin-top: 1.5%;
    font-size: 1.7vh;
    width: 100%;
    text-align: center;
}
.powered{
    position: absolute;
    color: #5D5C5B;
    margin-top: 1.3%;
    font-size: 2.65vh;
    margin-left: 51%;
}

.mob_powered{
    position: absolute;
    color: #5D5C5B;
    margin-top: 1.3%;
    font-size: 2vh;
    margin-left: 30%;
}

/* .handClass{ */
    /* position: absolute; */
    /* background-color: #5D5C5B; */
    /* bottom: 0; */
    /* margin-bottom: -10px; */
    /* width: 35%; */
    /* z-index: -1; */
/* } */

.phone{
    background-size:contain;
    background-position: center;
    /* width:250px; */
    /* height: 600px; */
    margin: 0 auto;
    margin-top: 6%;
    position: relative;
    margin-left: 20%;
}

.mob_phone{
    background-size:contain;
    background-position: center;
    margin: 0 auto;
    margin-top: 40%;
    position: relative;
    margin-left: 20%;
}

.follow{
    color:#7D4A16;
    position: absolute;
    margin-left: 35%;
    margin-top: 7%;
    font-size: 170%;
    display: flex;
}

.mob_follow{
    width: 100%;
    color:#7D4A16;
    margin-left: 35%;
    margin-top: 7%;
    font-size: 2vh;
    display: flex;
}

.usOn{
    margin-top: 17px;
    margin-right: 20px;
}

.mob_usOn{
    position: absolute;
    text-align: right;
    padding-left: 5%;
    margin-top: 14%;
}

.insta{
    margin-right: 10px;
}

.phoneTitle{
    position: relative;
    background-color: #CDCDCD;
    margin-top: 10px;
}

.l2{
    color: #6A3C0E;
    font-size: 3vh;
    padding-left: 10%;
    padding-right: 5%;
    margin-top: 75%;
}

.textfield-style::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CDCDCD;
    opacity: 1; /* Required for Firefox */
}

.textfield-style:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CDCDCD;
}

.textfield-style::-ms-input-placeholder { /* Microsoft Edge */
    color: #CDCDCD;
}

.mob_textfield-style::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CDCDCD;
    opacity: 1; /* Required for Firefox */
}

.mob_textfield-style:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CDCDCD;
}

.mob_textfield-style::-ms-input-placeholder { /* Microsoft Edge */
    color: #CDCDCD;
}

.textfield-style {
    padding: 10px;
    font-size: 2.5vh;
    border-radius: 10px;
    border:  #FFFFFF;
    color: #de8730;
    /* margin-left:  50%; */
    /* margin-top: 5%; */
    width: 45%;
    /* background-color: #6A3C0E; */
}

.mob_textfield-style {
    padding: 10px;
    border-radius: 10px;
    border:  #FFFFFF;
    border-style:solid;
    border-color: #de8730;
    color: #de8730;
    /* margin-left:  50%; */
    /* margin-top: 5%; */
    width: 90%;
    /* background-color: #6A3C0E; */
}

.textfield-style:focus {
    outline: none;
    /* Any other styles you want to reset when the input is focused */
    box-shadow: none; /* Some browsers might add a shadow */
}

.custom-button {
    /* margin-top: 43.5%; */
    width: 100%;
    margin-left: 60%;
    background-color: #F2AA61;
    color: white;
    font-size: 2.5vh;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.mob_custom-button {
    /* margin-top: 43.5%; */
    /* width: 100%; */
    background-color: #F2AA61;
    color: white;
    font-size: 2vh;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-top: 10%;
    transition: background-color 0.3s;
}

.input{
    position : absolute;
    display: flex;
    width: 50%;
    margin-left: 6%;
    margin-top: 1%;
    /* background-color: #5D5C5B; */
}

.mob_input{
    position : absolute;
    width: 50%;
    margin-left: 16%;
    margin-top: 1%;
    /* background-color: #5D5C5B; */
}
    


.bottom_popup{
    position:absolute;
    bottom : 0 ;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vh;
    padding: 5px 20px;
    /* color: #579012; */
    color: white;
    border-radius: 8px;
    font-weight: 700;
}

.bottom_popup_error{
    background-color: #d45741;
    z-index: 3;
}

.bottom_popup_success{
    background-color: green;
    z-index: 3;
}

.mob_bottom_popup_error{
    background-color: #d45741;
    z-index: 3;
    font-size: 1.5vh;
}

.mob_bottom_popup_success{
    background-color: green;
    z-index: 3;
    font-size: 1.5vh;
}

.fade-component {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: none;
  }
  
  .fade-component.visible {
    opacity: 1;
    display: block;
  }

.mob_newLetter{
    margin-top: 50%;
    margin-left: 25%;
    font-size: 1.5vh;
}

.mob_socialLogo{
    position:absolute;
    display: flex;
    margin-top: 70%;
}

.mob_insta{
    margin-left: 10px;
    margin-right: 10px;
}